//import Button from "react-bootstrap/Button";
import Register from "../components/register";
import { useState, useEffect } from "react";
import ForgotPassword from "../components/forgotPassword";
import homePage from "../DivHub.png";
import stockModal from "../HomePage.gif";
import addModal from "../addModal.png";
import calander from "../Calendar.gif";
import piechart from "../Overview.gif";
import barchart from "../financial-analysis.svg";
import SaaSFeature from "../components/SaaSFeature";
import ContactUs from "../components/ContactUs";
import Button from "react-bootstrap/Button";
import cash from "../Targets.gif";
import time from "../time-is-money.svg";
import { v4 as uuidv4 } from "uuid";
import Card from "../components/Card";
import Carousel from "../components/Carousel";

import styled from "styled-components";
import { motion } from "framer-motion";
import { TypeAnimation } from "react-type-animation";
import colours from "../colours";

const Container = styled.div`
  background-image: url("../background.png");
`;
const Card1 = styled(motion.div)`
  background-color: rgba(78, 122, 140, 0.9);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transform: translateX(-60px);
  padding: 30px;
  width: 80%;
  max-width: 1200px;
  margin-bottom: 50px;
  margin-right: 40px;
`;

const Title = styled.h2`
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  color: #333;
  margin: 0.5em 0;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #e2e2e2;
  padding-bottom: 0.3em;
`;

const Text = styled.p`
  font-size: 1.25rem;
  color: black;
  text-align: center;
  line-height: 1.6;
`;

function Home() {
  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card
          title="In-Depth Stock Information"
          text="Stay informed with detailed data on each stock, including volatility, yields, and investor ratings. Making informed decisions is crucial, and our platform equips you with the insights you need."
          imagen={stockModal}
        />
      ),
    },

    {
      key: uuidv4(),
      content: (
        <Card
          title="Interactive Calendar View"
          text="Stay organized with a user-friendly calendar view that displays relevant information about the stocks in your portfolio. You can quickly access dividend ex-dates and financial release dates, making planning a breeze."
          imagen={calander}
        />
      ),
    },

    {
      key: uuidv4(),
      content: (
        <Card
          title="Powerful Portfolio Analysis"
          text="Our sophisticated graphs help you analyze your portfolio effectively. Gain valuable insights into your investment performance, identifying trends and areas for improvement."
          imagen={cash}
        />
      ),
    },

    {
      key: uuidv4(),
      content: (
        <Card
          title="Visualize Your Portfolio"
          text="Our pie charts visually represent the distribution of your portfolio in terms of value and dividends received. This visualization empowers you to understand your portfolio's composition at a glance."
          imagen={piechart}
        />
      ),
    },
  ];
  const [show, setShow] = useState(false);

  const open = () => {
    setShow(true);
  };

  const close = () => {
    setShow(false);
  };
  const headingText =
    "The perfect solution to track, analyze, and plan your dividend portfolios...";
  return (
    <Container>
      <div style={mainContainerStyle}>
        <div style={textContainerStyle}>
          <div style={{ ...textStyle, marginLeft: 30 }}>
            THE PERFECT SOLUTION TO{" "}
            <span style={animatedWordStyle}>
              <TypeAnimation
                sequence={["TRACK", 3000, "ANALYSE", 3000, "PLAN", 3000]}
                speed={10}
                repeat={Infinity}
                cursor={false}
                deletionSpeed={10}
                style={{ color: "#4e7a8c", fontWeight: "bold" }}
              />
            </span>{" "}
            <br></br>
            <span style={newLineTextStyle}>YOUR DIVIDEND PORTFOLIO</span>
          </div>
        </div>
        <Card1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <Title>Join Us Today</Title>
          <Text>
            Take control of your portfolio as we invite you to join our
            community...
          </Text>
          <Button
            onClick={open}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            LOG IN / SIGN UP
          </Button>
        </Card1>
      </div>
      <Carousel
        cards={cards}
        height="400px"
        width="100%"
        margin="0 auto"
        offset={200}
        showArrows={false}
      />

      <Register setShow={setShow} show={show} close={close} />
    </Container>
  );
}
export default Home;

<Card1
  initial={{ opacity: 0, x: -20 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 0.8, delay: 0.3 }}
>
  <SaaSFeature
    imageSrc={homePage}
    id={1}
    title="Simplified Portfolio Management"
    text="We provide an intuitive interface for you to record all the stocks you own, making it easy to keep track of your investments..."
    isImageOnLeft={true}
  />
</Card1>;

{
  /* Repeat similar structure for other sections */
}
/*{
  key: uuidv4(),
  content: (
    <Card
      title="Extensive Stock Database"
      imagen={addModal}
      text="Gain access to a vast database of over 250,000 stocks across 5 regions. With this feature, you can easily search for stocks and add them to your personalized portfolio."
    />
  ),
},
{
  key: uuidv4(),
  content: (
    <Card
      title="Simplified Portfolio Management"
      imagen={homePage}
      text="We provide an intuitive interface for you to record all the stocks you own, making it easy to keep track of your investments in one place."
    />
  ),
},*/
const mainContainerStyle = {
  display: "flex",
  width: "100vw",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "1em", // Optional: adjust as needed
};

const textContainerStyle = {
  flex: "0 0 60%", // Takes up 70% of the width
  display: "flex",
  flexDirection: "column", // Stack elements vertically
  alignItems: "flex-start", // Align items to the start (left)
  justifyContent: "center", // Center vertically in the available space
};

const textStyle = {
  fontSize: "2em", // Base font size for static text
  whiteSpace: "nowrap",
  overflow: "hidden",
  textAlign: "left", // Ensures text alignment starts from the left
};

const animatedWordStyle = {
  fontSize: "2em", // Larger font size for the animated word
  color: "#4e7a8c",
  fontWeight: "bold",
};

const newLineTextStyle = {
  fontSize: "1.5em", // Same font size as the static part
  color: "#000", // Adjust color as needed
  marginTop: "0.5em", // Optional: add space above this line
  textAlign: "left", // Ensures text alignment starts from the left
};

/* {
  key: uuidv4(),
  content: (
    <Card
      title="Dividend Tracking"
      text="Never miss a dividend payment again! Our platform allows you to keep track of dividends received for each stock in your portfolio, ensuring you never overlook an important income stream."
      imagen={barchart}
    />
  ),
},

{
      key: uuidv4(),
      content: (
        <Card
          title="Dividend Date Chart"
          text="Track the dates of your dividends with ease! Our intuitive chart splits your dividends between your stocks, providing you with a clear and comprehensive view of your income flow."
          imagen={time}
        />
      ),
    },

*/
