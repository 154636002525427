import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "react-crud-icons";

import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  Bar,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { useEffect, useState } from "react";
import universalStyle from "../universalStyle";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";

function StockModalInfo(props) {
  const [sum, setSum] = useState(0);
  const location = useLocation();

  useEffect(() => {
    // Calculate the sum of 'div' values in the data array
    let totalSum = 0;
    for (const item of props.details.divDate) {
      totalSum += item.dividend;
    }
    setSum(totalSum);
  }, [props.details]); // Run this effect whenever the 'data' array changes

  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>{props.details.symbol}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <>
            <style>
              {`
          .fixed-icon {
            position: fixed 
            top: 10px;
            right: 10px;
       
          }
            .crud-icon > div {
            min-width: 400px;
            
          }
          
        `}
            </style>
            <div
              style={{
                display: "flex",
                marginBottom: 10,
                color: universalStyle.colours.tertiary,
                fontSize: 13,
                justifyContent: "space-between",
              }}
            >
              <div>
                <h5 style={{ color: universalStyle.colours.secondary }}>
                  Fundamentals:
                </h5>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Price:
                </span>{" "}
                {props.details.price
                  ? props.details.price.toFixed(2)
                  : props.details.price}
                <span
                  style={{ fontWeight: "bold", color: "black", marginLeft: 20 }}
                >
                  Weight:
                </span>{" "}
                {(
                  ((props.details.price * props.details.noOfShares) /
                    props.value) *
                  100
                ).toFixed(2)}
                %
                <span
                  style={{ fontWeight: "bold", color: "black", marginLeft: 20 }}
                >
                  Volatility:
                </span>{" "}
                {props.details.volatility}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: 10,
                color: universalStyle.colours.tertiary,
                fontSize: 13,
              }}
            >
              <div>
                <h5 style={{ color: universalStyle.colours.secondary }}>
                  Dividend Analysis:
                </h5>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Payout Ratio:
                </span>{" "}
                {props.details.payout}%{" "}
                <Icon
                  name="info"
                  tooltip="The Payout Ratio (TTM) shows what percentage of a company's earnings over the last 12 months (TTM = Trailing Twelve Months) was paid out to shareholders as dividends.

In simple terms, it answers:
How much of the company's profits are being given to investors versus kept for growth?

A high payout ratio (e.g., 80% or more) means the company is paying out most of its earnings as dividends, leaving less for reinvestment.
A low payout ratio (e.g., 20-30%) means the company is keeping more of its earnings to reinvest in the business."
                  theme="light"
                  size={"tiny"}
                  className="fixed-icon"
                />
                <br></br>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Div Cover :
                </span>{" "}
                {props.details.cover}{" "}
                <Icon
                  name="info"
                  tooltip="This measures how many times a company's profits can cover its dividends over the past 12 months.

💡 Think of it as: Does this company make enough money to afford its dividends?

A ratio above 2 means the company earns twice as much as it needs to pay dividends → safer dividends.
A ratio below 1 is a red flag → the company is paying out more than it earns.
Formula: Earnings per share (EPS) ÷ Dividend per share (DPS)"
                  theme="light"
                  size={"tiny"}
                  className="fixed-icon"
                />
                <br></br>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Div Per Share:
                </span>{" "}
                {props.details.dps}{" "}
                <Icon
                  name="info"
                  tooltip="This tells you how much cash a company has paid out per share in dividends over the past 12 months.

💡 Think of it as: For every share I own, how much did I actually receive in dividends?

A higher DPS means more earnings are being shared with investors.
Formula: Total dividends paid ÷ Number of shares"
                  theme="light"
                  size={"tiny"}
                  className="fixed-icon"
                />
              </div>
              <div style={{ marginTop: 27, marginLeft: 15 }}>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Cash Flow
                </span>{" "}
                {props.details.cashFlowCoverageRatiosTTM.toFixed(2)}{" "}
                <Icon
                  name="info"
                  tooltip="The Cash Flow Coverage Ratio (TTM) tells you how well a company can pay off its debts using its cash flow from operations over the past 12 months (TTM = Trailing Twelve Months).

In simple terms, it answers:
Does this company make enough cash to comfortably cover what it owes?

A higher ratio means the company has plenty of cas"
                  theme="light"
                  size={"tiny"}
                  className="fixed-icon"
                />
                <br></br>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Div Yield TTM:
                </span>{" "}
                {props.details.yield}%{" "}
                <Icon
                  name="info"
                  tooltip="This tells you how much return (in percentage) you're getting from dividends based on the stock price over the past 12 months (TTM = Trailing Twelve Months).

💡 Think of it as: If I invest $100 in this stock, how much would I earn from dividends in a year?

A higher yield means more dividend income relative to the stock price.
Formula: (Dividends per share ÷ Stock price) × 100"
                  theme="light"
                  size={"tiny"}
                  className="fixed-icon"
                />
                <br></br>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Div Yield(Cost):
                </span>{" "}
                {((sum / props.details.cost) * 100).toFixed(2)}%
                <Icon
                  name="info"
                  tooltip="This shows the dividend return based on the price you originally paid for the stock, rather than the current price.

💡 Think of it as: How much am I earning in dividends compared to what I paid when I bought this stock?

If a company increases its dividends over time, this yield can grow, making long-term investments more attractive."
                  theme="light"
                  size={"tiny"}
                  className="fixed-icon"
                />
              </div>
            </div>
            <h5>Investor Ratings:</h5>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: universalStyle.colours.secondary,
                fontSize: 13,
                fontWeight: "bold",
                color: "black",
              }}
            >
              <span>Strong Buy</span>
              <span>Buy</span>
              <span>Hold</span>
              <span>Sell</span>
              <span>Strong Sell</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between", // evenly distribute children
                alignItems: "center", // align items vertically in the center
                padding: "0 40px", // optional: add padding to the container for better spacing
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  color: universalStyle.colours.tertiary,
                }}
              >
                {JSON.stringify(props.details.rec?.analystRatingsStrongBuy)}
              </div>

              <div
                style={{
                  transform: "translateX(2px)",
                  textAlign: "center",
                  color: universalStyle.colours.tertiary,
                  marginLeft: 8,
                }}
              >
                {JSON.stringify(props.details.rec?.analystRatingsbuy)}
              </div>

              <div
                style={{
                  transform: "translateX(-9px)",

                  color: universalStyle.colours.tertiary,
                }}
              >
                {JSON.stringify(props.details.rec?.analystRatingsHold)}
              </div>

              <div
                style={{
                  transform: "translateX(-12px)",

                  textAlign: "center",
                  color: universalStyle.colours.tertiary,
                }}
              >
                {JSON.stringify(props.details.rec?.analystRatingsSell)}
              </div>

              <div
                style={{
                  transform: "translateX(10px)",

                  textAlign: "center",
                  color: universalStyle.colours.tertiary,
                }}
              >
                {JSON.stringify(props.details.rec?.analystRatingsStrongSell)}
              </div>
            </div>
            <div>
              <h5
                style={{
                  color: universalStyle.colours.secondary,
                  paddingTop: 10,
                }}
              ></h5>

              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th
                      colspan="4"
                      style={{
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: "#eee",
                      }}
                    >
                      {" "}
                      Financials ( £ 000,000):
                    </th>
                  </tr>
                  <tr>
                    <th style={{ padding: "10px", backgroundColor: "#f4f4f4" }}>
                      Year
                    </th>
                    <th style={{ padding: "10px", backgroundColor: "#f4f4f4" }}>
                      Revenue
                    </th>
                    <th style={{ padding: "10px", backgroundColor: "#f4f4f4" }}>
                      Costs
                    </th>
                    <th style={{ padding: "10px", backgroundColor: "#f4f4f4" }}>
                      Profit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[0][0]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[0][1]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[0][2]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {(
                        props.details?.financials[0][1] -
                        props.details?.financials[0][2]
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f9f9f9" }}>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[1][0]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[1][1]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[1][2]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {(
                        props.details?.financials[1][1] -
                        props.details?.financials[1][2]
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[2][0]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[2][1]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[2][2]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {(
                        props.details?.financials[2][1] -
                        props.details?.financials[2][2]
                      ).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5
              style={{ color: universalStyle.colours.secondary, marginTop: 10 }}
            >
              Graphs:
            </h5>
            Dividends received in the past 5 years
            <LineChart
              width={450}
              height={560}
              data={props.details.previousDiv}
              margin={{
                top: 5,
                right: 30,
                left: -20,
              }}
            >
              <Tooltip
                label={"Number"}
                labelFormatter={(unixTime) => [
                  new Date(unixTime * 1000).toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour12: false,
                  }),
                ]}
              />
              <YAxis />
              <XAxis
                tickMargin={49}
                height={140}
                angle={45}
                dataKey="date"
                scale={"time"}
                hide={false}
                type="number"
                domain={["auto", "auto"]}
                tickFormatter={(unixTime) =>
                  new Date(unixTime * 1000).toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour12: false,
                  })
                }
              />
              <Line dataKey="amount" fill="#E8DBCD" stroke="#E8DBCD" />
            </LineChart>
          </>
        </Modal.Body>

        {location.pathname == "/portfolio" ? (
          <Modal.Footer>
            <Button
              onClick={() => {
                props.handleShowEdit(
                  props.details._id,
                  props.details.cost,
                  props.details.symbol,
                  props.details.noOfShares,
                  props.details.divDate,
                  props.details.sold,
                  props.details.soldData,
                  props.details.buyData
                );
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                props.deleteStock(
                  props.details._id,
                  props.details.cost,
                  props.details.symbol,
                  props.details.noOfShares,
                  props.details.sold,
                  props.details.soldData,
                  props.details.buyData
                );
              }}
            >
              Remove
            </Button>{" "}
          </Modal.Footer>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
}

export default StockModalInfo;
