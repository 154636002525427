import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

function Card({ imagen, text, title }) {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    opacity: 1,
    transform: show ? "scale(2.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)",
  });

  return (
    <animated.div
      className={Styles.card}
      style={{
        ...props3,
        backgroundImage: show ? `url(${imagen})` : "none",
        backgroundSize: "cover ",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <div className={Styles.cardContent}>
        <h2 style={{ borderBottom: show ? "" : "2px solid #e2e2e2" }}>
          {show ? "" : title}
        </h2>
        <p>{show ? "" : text}</p>
      </div>
    </animated.div>
  );
}

export default Card;
